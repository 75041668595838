import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '@blueprintjs/core'
import { useTranslation } from 'react-i18next'

import AppContext from '../AppContext'

const Nav = ({ style }) => {
  const { t } = useTranslation()
  const { user, nav, history } = useContext(AppContext)
  const { pathname } = history.slice(-1)[0] || {}
  const currentPathname = `/${pathname ? pathname.split('/')[1] : ''}`

  return (
    <nav className="Nav" style={style}>
      <Menu>
        {nav.map(navItem => {
          const { title, pathname, onClick, button, requiredContext } = navItem
          const isActive = pathname === currentPathname

          switch (requiredContext) {
            case 'user':
              if (!user) {
                return null
              }
              break
            default:
              break
          }

          return (
            <MenuItem
              key={title}
              text={t(title)}
              onClick={onClick}
              title={title}
              active={isActive}
              {...button}
            />
          )
        })}
      </Menu>
    </nav>
  )
}

Nav.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
}

export default Nav
