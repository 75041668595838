import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { AnchorButton, Icon, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import page from 'page'

import { $, formatNumber } from '../formats'
import AppContext from '../AppContext'
import { groupBy, figures } from '../helpers'
import { Percentage, Change } from '../Partials'

class List extends Component {
  static contextType = AppContext
  static propTypes = {
    items: PropTypes.array.isRequired,
    isDetailed: PropTypes.bool
  }
  state = {
    lists: this.lists
  }

  get lists() {
    const { constants } = this.context
    const { ASSET_TYPES } = constants
    const types = Object.values(ASSET_TYPES)

    return types.map(type => ({
      type,
      isOpen: true
    }))
  }

  handleToggleList(index) {
    const { lists } = this.state
    const list = lists[index]
    const { isOpen } = list

    lists[index] = {
      ...list,
      isOpen: !isOpen
    }

    this.setState({ lists })
  }

  handleAssetDelete(item) {
    const { action } = this.context
    const { id, name } = item

    action.set('alert', {
      isOpen: true,
      icon: IconNames.DELETE,
      intent: Intent.DANGER,
      cancelButtonText: 'Cancel',
      canEscapeKeyCancel: true,
      canOutsideClickCancel: true,
      onClose: () => action.reset('alert'),
      onConfirm: () => {
        action.removeWhere('transactions', 'assetId', id, () => {
          action.remove('assets', id, () => {
            action.addToast({
              icon: IconNames.TICK,
              intent: Intent.SUCCESS,
              message: `${name} deleted`
            })
          })
        })
      },
      children: (
        <p>Are you sure you want to delete <strong>{name}</strong>?</p>
      )
    })
  }


  renderList(index, items) {
    const { lists } = this.state
    const list = lists[index]
    const { isOpen } = list
    const { constants } = this.context
    const { ASSET_TYPES } = constants
    const { CASH } = ASSET_TYPES

    if (!items || !items.length || !isOpen) {
      return null
    }

    return (
      <ol>
        {items.map((item, index) => {
          const { id, name, percent, type, value } = item
          return (
            <li key={index} className="Row">
              <div>
                <h3>
                  <AnchorButton minimal onClick={() => this.handleAssetDelete(item)}>
                    <Icon icon={IconNames.DELETE} iconSize={16} />
                  </AnchorButton>
                  {name}
                  {[CASH].includes(type) && (<Percentage value={percent} text="% APY" />)}
                  <AnchorButton minimal onClick={() => page(`/equity/${id}`)}>
                    <Icon icon={IconNames.LAYOUT_LINEAR} />
                  </AnchorButton>
                </h3>
              </div>
              <div>
                <span>{$(value)}</span>
              </div>
            </li>
          )
        })}
      </ol>
    )
  }

  renderValues(items) {
    const { total, change } = figures(items)
    const columns = [
      <span key="cost">{$(total.cost)}</span>,
      <span key="value">{$(total.value)}</span>,
      <Change key="dollar" tagName="span" value={change.costValue.dollar} text={$(change.costValue.dollar)} />,
      <Change key="percent" tagName="span" value={change.costValue.percent} text={`${formatNumber(change.costValue.percent)} %`} />
    ]

    if (!this.props.isDetailed) {
      return columns[1]
    }

    return columns
  }

  render() {
    const { t } = this.props
    const { lists } = this.state
    const groups = groupBy(this.props.items, item => item.type)

    if (!lists || !lists.length) {
      return null
    }

    return (
      <ol>
        {lists.map((list, index) => {
          const { type, isOpen } = list
          const icon = !isOpen
            ? IconNames.ADD
            : IconNames.REMOVE
          const items = groups.get(type)

          if (!items || !items.length) {
            return null
          }

          return (
            <li key={index}>
              <div className="Row">
                <div>
                  <h2>
                    {t(type, { returnObjects: true })[items.length === 1 ? 'title' : 'titlePlural']}
                    <AnchorButton
                      minimal
                      onClick={() => this.handleToggleList(index)}
                    >
                      <Icon icon={icon} iconSize={32} />
                    </AnchorButton>
                  </h2>
                </div>
                <div>
                  {this.renderValues(items)}
                </div>
              </div>
              {this.renderList(index, items)}
            </li>
          )
        })}
      </ol>
    )
  }
}

export default withTranslation('translations')(List)
