import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup } from '@blueprintjs/core'

const AddressInput = ({ item, change, getTranslation }) => {
  const { label, labelInfo, helperText, placeholder } = getTranslation('address')

  return (
    <FormGroup
      label={label}
      labelFor="address"
      labelInfo={labelInfo}
      helperText={helperText}
    >
      <InputGroup
        id="address"
        large
        type="text"
        placeholder={placeholder}
        onChange={event => change(event, 'address')}
        defaultValue={item.address}
      />
    </FormGroup>
  )
}

AddressInput.propTypes = {
  item: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired
}

export default AddressInput