import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import page from 'page'
import {
  Button,
  Intent,
  Classes,
  AnchorButton
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import uuidv4 from 'uuid/v4'

import AppContext from '../AppContext'
import { Initial } from '../constants'
import { classNames } from '../helpers'
import TypeInput from './TypeInput'

import NameInput from '../Partials/NameInput'
import SymbolInput from '../Partials/SymbolInput'
import CostInput from '../Partials/CostInput'
import ValueInput from '../Partials/ValueInput'
import PercentInput from '../Partials/PercentInput'
import UnitsInput from '../Partials/UnitsInput'
import LoanInput from '../Partials/LoanInput'
import AddressInput from '../Partials/AddressInput'
import DateInput from '../Partials/DateInput'

const Form = ({ mode, item }) => {
  const { t } = useTranslation()
  const { action, constants } = useContext(AppContext)
  const { ASSET_TYPES } = constants

  const [asset, setAsset] = useState(item)
  const [callback, setCallback] = useState(null)

  useEffect(() => {
    if (callback) {
      callback()
      setCallback(null)
    }
  }, [asset, callback])

  const resetState = () => {
    setAsset(Initial.ASSET)
  }

  const change = (event, key, transform, callback) => {
    const { target } = event
    let { value } = target

    if (transform) {
      value = transform(value)
    }

    setAsset(prevAsset => ({
      ...prevAsset,
      [key]: value
    }))
    if (callback) {
      setCallback(() => callback)
    }
  }

  const handleAddAssetSubmit = (event) => {
    event.preventDefault()
    const {
      type,
      name,
      cost,
      value,
      units,
      percent,
      loan,
      date
    } = asset
    const newAsset = {
      id: uuidv4(),
      type,
      name,
      percent,
      loan
    }
    const newTransaction = {
      id: uuidv4(),
      assetId: newAsset.id,
      date,
      cost,
      value, 
      units
    }

    action.add('assets', newAsset, () => {

      action.addToast({
        icon: IconNames.TICK,
        intent: Intent.SUCCESS,
        message: `${name} added to ${type}`
      })

      action.add('transactions', newTransaction, () => {
        action.reset('dialog')

        action.addToast({
          icon: IconNames.TICK,
          intent: Intent.SUCCESS,
          message: `${type} transaction added to ${name}`
        })

        resetState()
      })
    })
  }

  const handleEditAssetSubmit = (event) => {
    event.preventDefault()
    const {
      id,
      name,
      symbol,
      cost,
      value,
      units,
      percent,
      loan
    } = asset
    const updatedAsset = {
      name,
      symbol,
      cost,
      value,
      units,
      percent,
      loan
    }

    action.updateWhere('assets', 'id', id, updatedAsset, () => {
      action.reset('dialog')
      action.addToast({
        icon: IconNames.TICK,
        intent: Intent.SUCCESS,
        message: `${name} updated`
      })

      page.redirect(`/equity/${id}`)
    })
  }

  const getTranslation = (prefix) => {
    const assetType = asset.type
    return {
      label: t(assetType, { returnObjects: true })[prefix] || t(prefix.charAt(0).toUpperCase() + prefix.slice(1)),
      labelInfo: t(assetType, { returnObjects: true })[`${prefix}Information`],
      helperText: t(assetType, { returnObjects: true })[`${prefix}Description`],
      placeholder: t(assetType, { returnObjects: true })[`${prefix}Placeholder`] || t(prefix.charAt(0).toUpperCase() + prefix.slice(1))
    }
  }

  const renderInputs = () => {
    const { type } = asset
    const { CASH, SECURITY, PROPERTY, VEHICLE } = ASSET_TYPES

    switch (type) {
      case CASH:
        return (
          <>
            <NameInput item={asset} change={change} getTranslation={getTranslation} />
            <PercentInput item={asset} change={change} getTranslation={getTranslation} />
          </>
        )
      case SECURITY:
        return (
          <>
            <NameInput item={asset} change={change} getTranslation={getTranslation} />
            <SymbolInput item={asset} change={change} getTranslation={getTranslation} />
            <UnitsInput item={asset} change={change} getTranslation={getTranslation} />
          </>
        )
      case PROPERTY:
        return (
          <>
            <NameInput item={asset} change={change} getTranslation={getTranslation} />
            <AddressInput item={asset} change={change} getTranslation={getTranslation} />
          </>
        )
      case VEHICLE:
        return (
          <>
            <NameInput item={asset} change={change} getTranslation={getTranslation} />
            <LoanInput item={asset} change={change} getTranslation={getTranslation} />
            <PercentInput item={asset} change={change} getTranslation={getTranslation} />
          </>
        )
      default:
        return null
    }
  }

  return (
    <form onSubmit={event => mode === 'Add' ? handleAddAssetSubmit(event) : handleEditAssetSubmit(event)}>
      <div className={classNames([Classes.DIALOG_BODY, 'flex'])}>
        <div className={classNames(['flex-0', 'half'])}>
          {mode === 'Add' && <TypeInput item={asset} change={change} getTranslation={getTranslation} action={action} mode={mode} ASSET_TYPES={ASSET_TYPES} />}
          {renderInputs()}
        </div>
        <div className={classNames(['flex-0', 'half'])}>
          <CostInput item={asset} change={change} getTranslation={getTranslation} />
          <ValueInput item={asset} change={change} getTranslation={getTranslation} />
          <DateInput item={asset} setAsset={setAsset} getTranslation={getTranslation} />
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <AnchorButton
            text="Cancel"
            onClick={() => action.reset('dialog')}
          />
          <Button
            type="submit"
            text={mode}
            intent={Intent.PRIMARY}
          />
        </div>
      </div>
    </form>
  )
}

Form.propTypes = {
  mode: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired
}

Form.defaultProps = {
  mode: 'Add', // 'Edit'
  item: Initial.ASSET
}

export default Form
