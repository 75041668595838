import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup } from '@blueprintjs/core'
import { useTranslation } from 'react-i18next'
import { classNames } from '../helpers'
import Form from './Form'

const TypeInput = ({ item, parentItem, change, getTranslation, action, mode, ASSET_TYPES }) => {
  const { t } = useTranslation()
  const { label, labelInfo, helperText } = getTranslation('type')

  return (
    <FormGroup
      labelFor="type"
      label={label}
      labelInfo={labelInfo}
      helperText={helperText}
    >
      <div className={classNames(['bp3-select', 'bp3-large', 'bp3-minimal', 'bp3-fill'])}>
        <select
          id="type"
          onChange={event => change(event, 'type', false, () => {
            action.update('dialog', {
              children: <Form mode={mode} item={item} parentItem={parentItem} />
            })
          })}
          required
          value={item.type}
        >
          {Object.entries(ASSET_TYPES).map(([key, value]) => (
            <option key={key} value={value}>{t(value, { returnObjects: true }).title}</option>
          ))}
        </select>
      </div>
    </FormGroup>
  )
}

TypeInput.propTypes = {
  item: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  ASSET_TYPES: PropTypes.object.isRequired
}

export default TypeInput