import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Content } from '../../Partials'

class Home extends PureComponent {
  static propTypes = {
    style: PropTypes.object
  }

  render() {
    return (
      <Content style={this.props.style}>
        <h1>Discover Your True Net Worth with {process.env.APP_NAME}</h1>
        <p>Take control of your financial future with {process.env.APP_NAME}, the web app designed to help you track the market value of all your assets and calculate your net worth effortlessly.</p>

        <h2>Why Choose {process.env.APP_NAME}?</h2>

        <h3>Comprehensive Asset Tracking</h3>
        <ul>
          <li><strong>Real Estate</strong>: Monitor the market value of your properties and stay updated with the latest trends.</li>
          <li><strong>Vehicles</strong>: Keep track of the depreciation and current value of your cars, motorcycles, and other vehicles.</li>
          <li><strong>Cash</strong>: Manage your cash holdings across multiple accounts and currencies.</li>
          <li><strong>Stock</strong>: Stay informed about your stock portfolio's performance and market fluctuations.</li>
        </ul>

        <h3>Accurate Net Worth Calculation</h3>
        <p>Get a clear picture of your financial health by calculating your net worth in real-time. Understand the true value of your assets and liabilities to make informed financial decisions.</p>

        <h3>User-Friendly Interface</h3>
        <p>Our intuitive and easy-to-use interface makes tracking your assets simple. Access detailed reports and visualizations to better understand your financial situation.</p>

        <h3>Secure and Private</h3>
        <p>Your financial data is protected with industry-leading security measures. We prioritize your privacy and ensure that your information is safe and secure.</p>

        <h3>Stay Informed</h3>
        <p>Receive timely notifications and alerts about significant changes in the market value of your assets. Stay ahead with personalized financial insights and recommendations.</p>

        <h2>Take Charge of Your Financial Future</h2>
        <p>With {process.env.APP_NAME}, you can manage your assets confidently and make informed decisions to grow your wealth. Whether you're a seasoned investor or just starting, our app provides the tools you need to achieve your financial goals.</p>

        <h2>Get Started Today</h2>
        <p>Visit {process.env.APP_NAME} and join the community of users who are taking control of their financial future. Start your journey to financial empowerment now.</p>
      </Content>
    )
  }
}

export default Home
