import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup } from '@blueprintjs/core'

const SymbolInput = ({ item, change, getTranslation }) => {
  const { label, labelInfo, helperText, placeholder } = getTranslation('symbol')

  return (
    <FormGroup
      label={label}
      labelFor="symbol"
      labelInfo={labelInfo}
      helperText={helperText}
    >
      <InputGroup
        id="symbol"
        large
        placeholder={placeholder}
        onChange={event => change(event, 'symbol')}
        required
        defaultValue={item.symbol}
      />
    </FormGroup>
  )
}

SymbolInput.propTypes = {
  item: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired
}

export default SymbolInput