import React from 'react'
import page from 'page'
import { createAuth0Client } from '@auth0/auth0-spa-js'
import { Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import client from './client'
import { Home, Dashboard, Portfolio, Settings, Equity } from './Containers'
import NotFound from './Errors/404'
import { Scroll } from './Partials/constants'
import { Structure } from './constants'

let app
let isValidUser
const props = {
  style: Scroll.DEFAULT
}

const getAuthenticatedUser = async (auth) => {
  const isAuthenticated = await auth.isAuthenticated()
  const user = isAuthenticated ? await auth.getUser() : null

  return user
}

const login = async (_context, next) => {
  const { state } = app
  const { auth } = state

  isValidUser
    ? next()
    : await auth.loginWithRedirect()
}

function findById(context, next) {
  const { params } = context
  const { id } = params
  const { state } = app
  const items = state.assets
  const item = context.item = items.find(item => item.id === id)
  item
    ? next()
    : client(<NotFound {...props} {...context} />)
}

page('*', async (context, next) => {
  const auth0 = await createAuth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI
    }
  })
  app = client({ auth0 })

  const { action } = app

  const user = action.get('user')

  const { canonicalPath, pathname } = context

  isValidUser = !!user && Object.keys(user).length > 0
  action.add('history', { canonicalPath, pathname })

  next()
})

/**
 * Auth
 */
page('/callback', async () => {
  const { state, action } = app
  const { auth } = state

  try {
    await auth.handleRedirectCallback()

    const user = await getAuthenticatedUser(auth)

    action.set('user', user, () => {
      action.setStructure(Structure.AUTHENTICATED, () => {
        page.redirect('/')
      })
    })

    action.addToast({
      icon: IconNames.TICK,
      intent: Intent.SUCCESS,
      message: `${user.email} logged in`
    })
  } catch (error) {
    action.addToast({
      icon: IconNames.TICK,
      intent: Intent.DANGER,
      message: `${error}`
    })
  }
})

page('/login', login)

/**
 * Public
 */
page('/', () => {
  client({ children: <Home {...props} /> })
})

/**
 * Registered
 */
page('/user', login, () => {
  client({ children: <Dashboard {...props} /> })
})
page('/portfolio', login, () => {
  client({ children: <Portfolio {...props} /> })
})
page('/equity/:id', login, findById, (context) => {
  const { item } = context

  client({ children: <Equity item={item} {...props} /> })
})
page('/settings', login, () => {
  client({ children: <Settings {...props} /> })
})

/**
 * Catch-all
 */
page('*', (context) => {
  client({ children: <NotFound {...props} {...context} /> })
})

page()
