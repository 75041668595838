import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { unformat, formatNumber } from '../formats'

const ValueInput = ({ item, change, getTranslation }) => {
  const { label, labelInfo, helperText, placeholder } = getTranslation('value')

  return (
    <FormGroup
      label={label}
      labelFor="value"
      labelInfo={labelInfo}
      helperText={helperText}
    >
      <InputGroup
        id="value"
        large
        type="text"
        placeholder={placeholder}
        onChange={event => change(event, 'value', unformat)}
        required
        leftIcon={IconNames.DOLLAR}
        defaultValue={formatNumber(item.value)}
      />
    </FormGroup>
  )
}

ValueInput.propTypes = {
  item: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired
}

export default ValueInput