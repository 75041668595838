import React from 'react'
import PropTypes from 'prop-types'

import Content from '../Partials/Content'

const NotFound = ({ path, style }) => {
  return (
    <Content style={style}>
      <p>The requested URL <em>{path}</em> was not found.</p>
    </Content>
  )
}

NotFound.propTypes = {
  path: PropTypes.string,
  style: PropTypes.object
}

NotFound.defaultProps = {
  path: '/'
}

export default NotFound
