import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Content from '../../Partials/Content'

class Dashboard extends PureComponent {
  static propTypes = {
    style: PropTypes.object
  }

  render() {
    return (
      <Content className="Dashboard" style={this.props.style}>
        <h1>Dashboard</h1>
      </Content>
    )
  }
}

export default Dashboard
