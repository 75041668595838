import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { form2js } from 'form2js'
import { Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import merge from 'lodash.merge'

import AppContext from '../../AppContext'
import { Content } from '../../Partials'

const Settings = ({ style }) => {
  const { t } = useTranslation()
  const { action, constants, settings } = useContext(AppContext)
  const { ASSET_TYPES } = constants

  const isHash = (hash) => Object.getPrototypeOf(hash) === Object.prototype

  const handleSubmit = (event) => {
    event.preventDefault()

    const { target } = event
    const formData = form2js(target, null, false)
    const payload = merge({}, settings, formData.settings)

    action.update('settings', payload, () => {
      action.reset('dialog')
      action.addToast({
        icon: IconNames.TICK,
        intent: Intent.SUCCESS,
        message: `settings updated`
      })
    })
  }

  const renderKeyValue = (key, value, keychain) => {
    if (key.startsWith('_')) {
      return (
        <label key={key}>
          {key.substring(1)}
          <input
            type="input"
            defaultValue={value}
            name={`settings${keychain.map(k => `[${k}]`).join('')}[${key}]`}
          />
        </label>
      )
    }

    return (
      <label key={key}>
        {key}
        {value}
      </label>
    )
  }

  const renderList = (hash, keychain = []) => {
    if (!hash) {
      return null
    }

    return (
      <ul>
        {isHash(hash) && Object.entries(hash).map(([key, value]) => {
          const childIsHash = value && isHash(value)
          return (
            <li key={key}>
              {childIsHash && (
                <>
                  <h3>{key}</h3>
                  {renderList(value, [...keychain, key])}
                </>
              )}
              {!childIsHash && (
                <>{renderKeyValue(key, value, [...keychain])}</>
              )}
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <Content className="Settings" style={style}>
      <form onSubmit={handleSubmit}>
        <h1>Settings</h1>
        <ul>
          {Object.entries(ASSET_TYPES).map(([key, value]) => (
            <li key={key}>
              <h2>{t(value, { returnObjects: true }).title}</h2>
              {renderList(settings[value], [value])}
            </li>
          ))}
        </ul>

        <button type="submit">Save</button>
      </form>
    </Content>
  )
}

Settings.propTypes = {
  style: PropTypes.object
}

export default Settings
