import React from 'react'
import merge from 'lodash.merge'

import { user, settings, assets, transactions } from './env'
import { Initial, Structure } from './constants'
import { flattenByProp } from './helpers'

const isValidUser = !!user && Object.keys(user).length > 0
const hasAssets = !!assets && Array.isArray(assets) && !!assets.length
const hasTransactions = !!transactions && Array.isArray(transactions) && !!transactions.length

const AppContext = React.createContext({
  structure: isValidUser
    ? Structure.AUTHENTICATED
    : Initial.STRUCTURE,
  layout: isValidUser
    ? flattenByProp('children', Structure.AUTHENTICATED)
    : Initial.LAYOUT,
  nav: Initial.NAV,
  header: Initial.HEADER,
  notification: Initial.NOTIFICATION,
  dialog: Initial.DIALOG,
  alert: Initial.ALERT,
  user: isValidUser ? user : Initial.USER,
  settings: merge({}, Initial.SETTINGS, settings),
  history: Initial.HISTORY,
  constants: Initial.CONSTANTS,
  assets: hasAssets ? assets : Initial.ASSETS,
  transactions: hasTransactions ? transactions : Initial.TRANSACTIONS
})

export const { Provider, Consumer } = AppContext
export default AppContext