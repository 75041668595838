import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { unformat, formatNumber } from '../formats'

const LoanInput = ({ item, change, getTranslation }) => {
  const { label, labelInfo, helperText, placeholder } = getTranslation('loan')

  return (
    <FormGroup
      label={label}
      labelFor="loan"
      labelInfo={labelInfo}
      helperText={helperText}
    >
      <InputGroup
        id="loan"
        large
        type="text"
        placeholder={placeholder}
        onChange={event => change(event, 'loan', unformat)}
        required
        leftIcon={IconNames.DOLLAR}
        defaultValue={formatNumber(item.loan)}
      />
    </FormGroup>
  )
}

LoanInput.propTypes = {
  item: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired
}

export default LoanInput