import { Position } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import page from 'page'

import { flattenByProp } from './helpers'
import Header from './Header'
import Main from './Main'
import Nav from './Nav'
import Footer from './Footer'
import { Visibility } from './Nav/constants'

const CONSTANTS = {
  TRANSACTION_TYPES: {
    DEPOSIT: 'deposit',
    WITHDRAWAL: 'withdrawal',
    BUY: 'buy',
    SELL: 'sell'
  }
}

export const Constants = Object.freeze({
  ...CONSTANTS,
  ASSET_TYPES: {
    CASH: 'cash',
    PROPERTY: 'property',
    SECURITY: 'security',
    VEHICLE: 'vehicle'
  },
  DEBITS: [
    CONSTANTS.TRANSACTION_TYPES.DEPOSIT,
    CONSTANTS.TRANSACTION_TYPES.BUY
  ],
  CREDITS: [
    CONSTANTS.TRANSACTION_TYPES.WITHDRAWAL,
    CONSTANTS.TRANSACTION_TYPES.SELL
  ]
})

export const Component = {
  HEADER: { component: Header },
  MAIN: { component: Main },
  NAV: {
    component: Nav,
    style: Visibility.OPENED, // Visibility.CLOSED,
    state: {
      isOpen: false,
      isOver: false
    }
  },
  FOOTER: { component: Footer }
}

Component.NAV.state.isOpen = Component.NAV.style === Visibility.OPENED

export const Structure = Object.freeze({
  DEFAULT: [
    Component.HEADER,
    { ...Component.MAIN, children: [Component.NAV] },
  ],
  AUTHENTICATED: [
    Component.HEADER,
    { ...Component.MAIN, children: [Component.NAV] },
  ]
})

export const NavItem = Object.freeze({
  DASHBOARD: {
    title: 'Dashboard',
    pathname: '/user',
    onClick: () => {
      page(NavItem.DASHBOARD.pathname)
    },
    button: {
      icon: IconNames.DASHBOARD
    },
    requiredContext: 'user'
  },
  PORTFOLIO: {
    title: 'Portfolio',
    pathname: '/portfolio',
    onClick: () => {
      page(NavItem.PORTFOLIO.pathname)
    },
    button: {
      icon: IconNames.CHART
    },
    requiredContext: 'user'
  },
  SETTINGS: {
    title: 'Settings',
    pathname: '/settings',
    onClick: () => {
      page(NavItem.SETTINGS.pathname)
    },
    button: {
      icon: IconNames.COG
    },
    requiredContext: 'user'
  },
})

export const Initial = Object.freeze({
  STRUCTURE: Structure.DEFAULT,
  LAYOUT: flattenByProp('children', Structure.DEFAULT),
  NAV: [
    NavItem.DASHBOARD,
    NavItem.PORTFOLIO,
  ],
  HEADER: [
    NavItem.SETTINGS,
  ],
  NOTIFICATION: {
    toasts: [],
    toaster: {
      position: Position.TOP
    }
  },
  DIALOG: {
    isOpen: false
  },
  ALERT: {
    isOpen: false
  },
  USER: null,
  HISTORY: [],
  CONSTANTS: Constants,
  ASSET: {
    type: Constants.ASSET_TYPES.CASH,
    name: '',
    cost: 0.0,
    value: 0.0,
    units: 0.0,
    percent: 0.0,
    loan: 0.0
  },
  ASSETS: [],
  TRANSACTION: {
    assetId: null,
    type: null,
    date: new Date(),
    cost: 0.0,
    value: 0.0,
    units: 0.0
  },
  TRANSACTIONS: [],
  SETTINGS: {
    [Constants.ASSET_TYPES.CASH]: {
      API: {
        PLAID: {
          name: 'Plaid',
          url: 'https://plaid.com/docs/transactions/',
          _key: null
        }
      }
    },
    [Constants.ASSET_TYPES.PROPERTY]: {
      API: {
        ZILLOW: {
          name: 'Zillow',
          url: 'https://www.zillowgroup.com/developers/api/zestimate/zestimates-api/',
          _key: null
        }
      }
    },
    [Constants.ASSET_TYPES.SECURITY]: {
      API: {
        POLYGON: {
          name: 'Polygon',
          url: 'https://polygon.io/',
          _key: null,
        },
        COINBASE: {
          name: 'Coinbase',
          url: 'https://developers.coinbase.com/',
          _key: null
        }
      },
    },
    [Constants.ASSET_TYPES.VEHICLE]: {
      API: {
        EDMUNDS: {
          name: 'Edmunds',
          url: 'https://developer.edmunds.com/',
          _key: null
        }
      }
    }
  }
})
