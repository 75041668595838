import React from 'react'
import { render } from 'react-dom'

import App from './App'

const client = ({ children = null, auth0, ...rest }) => {
  const props = {
    ...rest,
    auth: auth0
  }

  return render(
    <App {...props}>
      {children}
    </App>,
    document.getElementById('root')
  )
}

export default client
