import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Intent,
  AnchorButton,
  Button,
  Classes,
  Checkbox
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import uuidv4 from 'uuid/v4'
import { useTranslation } from 'react-i18next'

import AppContext from '../AppContext'
import { Initial } from '../constants'
import { classNames } from '../helpers'
import TypeInput from './TypeInput'

import CostInput from '../Partials/CostInput'
import ValueInput from '../Partials/ValueInput'
import UnitsInput from '../Partials/UnitsInput'
import DateInput from '../Partials/DateInput'

const Form = ({ mode, item, parentItem }) => {
  const { t } = useTranslation()
  const { action, constants } = useContext(AppContext)
  const { ASSET_TYPES, CREDITS } = constants
  const { CASH, SECURITY } = ASSET_TYPES

  const [transaction, setTransaction] = useState(item)
  const [asset, setAsset] = useState(parentItem)
  const [isInterest, setIsInterest] = useState(false)
  const [callback, setCallback] = useState(null)

  useEffect(() => {
    if (callback) {
      callback()
      setCallback(null)
    }
  }, [transaction, callback])

  const resetState = () => {
    setTransaction(Initial.TRANSACTION)
  }

  const change = (event, key, transform, callback) => {
    const { target } = event
    let { value } = target

    if (transform) {
      value = transform(value)
    }

    setTransaction(prevTransaction => ({
      ...prevTransaction,
      [key]: value
    }))
    if (callback) {
      setCallback(() => callback)
    }
  }

  const handleAddTransactionSubmit = (event) => {
    event.preventDefault()
    const { id, name, type: assetType } = parentItem
    const {
      type,
      date,
      cost,
      value,
      units
    } = transaction
    const newTransaction = {
      id: uuidv4(),
      assetId: id,
      type,
      date,
      cost,
      value,
      units
    }
    const isNegative = CREDITS.includes(type)

    newTransaction.value = isNegative ? -value : value
    newTransaction.cost = isNegative ? -cost : cost

    switch (assetType) {
      case CASH:
        newTransaction.cost = isInterest ? 0.0 : newTransaction.value
        break
      default:
        break
    }

    action.add('transactions', newTransaction, () => {
      action.reset('dialog')
      action.addToast({
        icon: IconNames.TICK,
        intent: Intent.SUCCESS,
        message: `${type} transaction added to ${name}`
      })
      resetState()
    })
  }

  const renderInputs = () => {
    const assetType = parentItem.type

    switch (assetType) {
      case CASH:
        return (
          <>
            <Checkbox
              checked={isInterest}
              label="Interest"
              onChange={() => setIsInterest(!isInterest)}
            />
            {!isInterest && (
              <TypeInput
                item={transaction}
                parentItem={parentItem}
                change={change}
                getTranslation={getTranslation}
                action={action}
                mode={mode}
                ASSET_TYPES={constants.TRANSACTION_TYPES}
              />
            )}
            <ValueInput item={asset} change={change} getTranslation={getTranslation} />
            <DateInput item={asset} setAsset={setAsset} getTranslation={getTranslation} />
          </>
        )
      case SECURITY:
        return (
          <>
            <CostInput item={transaction} change={change} getTranslation={getTranslation} />
            <UnitsInput item={transaction} change={change} getTranslation={getTranslation} />
          </>
        )
      default:
        return null
    }
  }

  const getTranslation = (prefix) => {
    const assetType = parentItem.type
    return {
      label: t(assetType, { returnObjects: true })[prefix] || t(prefix.charAt(0).toUpperCase() + prefix.slice(1)),
      labelInfo: t(assetType, { returnObjects: true })[`${prefix}Information`],
      helperText: t(assetType, { returnObjects: true })[`${prefix}Description`],
      placeholder: t(assetType, { returnObjects: true })[`${prefix}Placeholder`] || t(prefix.charAt(0).toUpperCase() + prefix.slice(1))
    }
  }

  return (
    <form onSubmit={event => handleAddTransactionSubmit(event)}>
      <div className={classNames([Classes.DIALOG_BODY, 'flex'])}>
        <div className={classNames(['flex-1'])}>
          {renderInputs()}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <AnchorButton
            text="Cancel"
            onClick={() => action.reset('dialog')}
          />
          <Button
            type="submit"
            text={mode}
            intent={Intent.PRIMARY}
          />
        </div>
      </div>
    </form>
  )
}

Form.propTypes = {
  mode: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  parentItem: PropTypes.object.isRequired
}

Form.defaultProps = {
  mode: 'Add', // 'Edit'
  item: Initial.TRANSACTION,
  parentItem: Initial.ASSET
}

export default Form
