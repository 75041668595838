import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { formatNumber } from '../formats'

const PercentInput = ({ item, change, getTranslation }) => {
  const { label, labelInfo, helperText, placeholder } = getTranslation('percent')

  return (
    <FormGroup
      label={label}
      labelFor="percent"
      labelInfo={labelInfo}
      helperText={helperText}
    >
      <InputGroup
        id="percent"
        large
        type="number"
        placeholder={placeholder}
        step="0.01"
        onChange={event => change(event, 'percent', parseFloat)}
        required
        leftIcon={IconNames.PERCENTAGE}
        defaultValue={formatNumber(item.percent)}
      />
    </FormGroup>
  )
}

PercentInput.propTypes = {
  item: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired
}

export default PercentInput