const env = () => {
  const { env, localStorage } = window
  return {
    ...env,
    ...JSON.parse(localStorage.getItem('env'))
  }
}

export const {
  user,
  settings,
  assets,
  transactions
} = env()