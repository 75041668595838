import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        costBasis: 'Cost Basis',
        liabilities: 'Liabilities',
        netWorth: 'Net Worth',
        cash: {
          cost: 'Cost',
          costBasis: 'Cost Basis',
          liability: 'Liability',
          percent: 'APY',
          percentInformation: 'Annual Percentage Yield',
          percentDescription: 'The annual percentage yield (APY) is the effective annual rate of return taking into account the effect of compounding interest.',
          percentPlaceholder: '2.25',
          title: 'Cash',
          titlePlural: 'Cash',
          typeDescription: 'Savings, checking, money market, or other cash asset.',
          namePlaceholder: 'Bank - Personal Checking',
          value: 'Balance',
          valueInformation: 'Value',
          valueDescription: 'The value of the cash asset.'   ,
          valuePlaceholder: '10,000.00' 
        },
        property: {
          cost: 'Purchase Price',
          costBasis: 'Down Payment',
          liability: 'Mortgage',
          title: 'Real Estate',
          titlePlural: 'Real Estate',
          value: 'Market Value'
        },
        security: {
          buy: 'Buy',
          cost: 'Cost',
          costBasis: 'Cost Basis',
          liability: 'Liability',
          sell: 'Sell',
          title: 'Security',
          titlePlural: 'Securities',
          value: 'Value'
        },
        vehicle: {
          cost: 'Purchase Price',
          costBasis: 'Down Payment',
          liability: 'Loan Amount',
          title: 'Vehicle',
          titlePlural: 'Vehicles',
          value: 'Market Value'
        }
      }
    }
  },
  fallbackLng: 'en',
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
  react: {
    useSuspense: false
  }
})

export default i18n
