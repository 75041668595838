import React, { useState, useContext } from 'react'
import { Intent, AnchorButton, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { useTranslation } from 'react-i18next'

import { Initial } from '../constants'
import { classNames } from '../helpers'
import AppContext from '../AppContext'
import List from './List'
import AssetForm from './Form'

const Assets = () => {
  const { t } = useTranslation()
  const { action, constants, assets } = useContext(AppContext)
  const { ASSET_TYPES } = constants
  const types = Object.values(ASSET_TYPES)

  const [asset, setAsset] = useState(Initial.ASSET)
  const [isDetailed, setIsDetailed] = useState(false)

  const handleDetailChange = () => {
    setIsDetailed(!isDetailed)
  }

  const handleAddAsset = () => {
    setAsset(prevAsset => ({
      ...prevAsset,
      type: prevAsset.type || types[0]
    }))

    action.set('dialog', {
      isOpen: true,
      title: t('Add asset'),
      icon: IconNames.SERIES_ADD,
      onClose: () => action.reset('dialog'),
      children: <AssetForm mode="Add" item={asset} />
    })
  }

  const renderList = () => {
    if (!assets || !assets.length) {
      return null
    }

    return (
      <main>
        <h1>{t('Assets')}</h1>
        <Switch large name="asset-detail" checked={isDetailed} onChange={handleDetailChange} />
        <List items={assets} isDetailed={isDetailed} />
      </main>
    )
  }

  return (
    <div className={classNames(['Container', 'Assets'])}>
      <header>
        <AnchorButton
          large
          minimal
          text={t('Add asset')}
          icon={IconNames.SERIES_ADD}
          intent={Intent.PRIMARY}
          onClick={handleAddAsset}
        />
      </header>
      {renderList()}
    </div>
  )
}

export default Assets
