export function classNames(classes) {
  return classes.filter(x => !!x).join(' ')
}

export function flattenByProp(prop, list, flat = []) {
  list.forEach(item => {
    flat.push(item)
    if (item[prop]) {
      return flattenByProp(prop, item[prop], flat)
    }
  })
  return flat
}

export function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach(item => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export function sum(list, key) {
  if (!list || !list.length) {
    return
  }
  return list.map(item => item[key]).filter(Number).reduce((a, b) => a + b, 0)
}

export function percentChange(a, b) {
  return ((b - a) / a) * 100.0
}

export function figures(items) {
  const total = {
    value: sum(items, 'value'),
    cost: sum(items, 'cost'),
    liability: sum(items, 'loan')
  }
  const change = {
    costValue: {
      dollar: total.value - total.cost,
      percent: percentChange(total.cost, total.value)
    }
  }
  return { total, change }
}

export function sortByDate(a, b) {
  return new Date(a.date) - new Date(b.date);
}
