import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { unformat, formatNumber } from '../formats'

const CostInput = ({ item, change, getTranslation }) => {
  const { label, labelInfo, helperText, placeholder } = getTranslation('cost')

  return (
    <FormGroup
      label={label}
      labelFor="cost"
      labelInfo={labelInfo}
      helperText={helperText}
    >
      <InputGroup
        id="cost"
        large
        type="text"
        placeholder={placeholder}
        onChange={event => change(event, 'cost', unformat)}
        required
        leftIcon={IconNames.DOLLAR}
        defaultValue={formatNumber(item.cost)}
      />
    </FormGroup>
  )
}

CostInput.propTypes = {
  item: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired
}

export default CostInput