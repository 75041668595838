import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Intent,
  AnchorButton
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { Initial } from '../constants'
import AppContext from '../AppContext'
import List from './List'
import TransactionForm from './Form'

const Transactions = ({ item }) => {
  const { action, constants, transactions } = useContext(AppContext)
  const { TRANSACTION_TYPES } = constants
  const types = Object.values(TRANSACTION_TYPES)

  const [transaction, setTransaction] = useState(Initial.TRANSACTION)

  const handleAddTransaction = () => {
    setTransaction(prevTransaction => ({
      ...prevTransaction,
      type: prevTransaction.type || types[0]
    }))

    action.set('dialog', {
      isOpen: true,
      title: 'Add Transaction',
      icon: IconNames.SERIES_ADD,
      style: {
        width: '60rem'
      },
      onClose: () => action.reset('dialog'),
      children: <TransactionForm mode="Add" item={transaction} parentItem={item} />
    })
  }

  const renderList = () => {
    const { id, type } = item
    const items = transactions.filter(transaction => transaction.assetId === id)

    return <List items={items} type={type} />
  }

  return (
    <div className="Container Transactions">
      <header>
        <AnchorButton
          large
          minimal
          text="Add Transaction"
          icon={IconNames.SERIES_ADD}
          intent={Intent.PRIMARY}
          onClick={handleAddTransaction}
        />
      </header>
      <main>
        {renderList()}
      </main>
    </div>
  )
}

Transactions.propTypes = {
  item: PropTypes.object.isRequired
}

export default Transactions
