import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { AnchorButton, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import AppContext from '../../AppContext'
import Transactions from '../../Transactions/Transactions'
import AssetForm from '../../Assets/Form'
import { Content, Change, Percentage } from '../../Partials'
import { Cushion } from '../../Partials/constants'
import { figures } from '../../helpers'
import { $, safeFormatNumber } from '../../formats'

import './style.scss'

class Equity extends PureComponent {
  static contextType = AppContext
  static propTypes = {
    style: PropTypes.object,
    item: PropTypes.object.isRequired
  }
  state = {
    asset: this.props.item,
    showingBasicCostValue: true
  }

  handleShowingCostValueChange() {
    this.setState(state => ({
      showingBasicCostValue: !state.showingBasicCostValue
    }))
  }

  handleEditAsset() {
    const { action } = this.context

    action.set('dialog', {
      isOpen: true,
      title: 'Edit Asset',
      icon: IconNames.SERIES_ADD,
      onClose: () => action.reset('dialog'),
      children: <AssetForm mode="Edit" item={this.state.asset} />
    })
  }

  renderHeader() {
    const { item, t } = this.props
    const { id, name, type, percent } = item
    const { constants, transactions } = this.context
    const items = transactions.filter(item => item.assetId === id)
    const { total, change } = figures([item, ...items])
    const { ASSET_TYPES } = constants
    const { CASH } = ASSET_TYPES

    return (
      <header>
        <h1>{type}</h1>
        <h2>
          <AnchorButton minimal large="true" icon={IconNames.DELETE} />
          {name}
          {[CASH].includes(type) && (<Percentage value={percent} text="% APY" />)}
          <AnchorButton
            minimal
            large
            icon={IconNames.EDIT}
            onClick={() => this.handleEditAsset()}
          />
        </h2>

        {total.liability > 0 && (
          <Switch large checked={this.state.showingBasicCostValue} onChange={() => this.handleShowingCostValueChange()} />
        )}

        {this.state.showingBasicCostValue && (
          <>
            <h1>{t(type, { returnObjects: true }).value}</h1>
            <h2>{$(total.value)}</h2>
            <h1>{t(type, { returnObjects: true }).cost}</h1>
            <h2>{$(total.cost)}</h2>
          </>
        )}

        {!this.state.showingBasicCostValue && (
          <>
            <h1>Equity</h1>
            <h2>{$(total.value - total.liability)}</h2>
            <h1>{t(type, { returnObjects: true }).costBasis}</h1>
            <h2>{$(total.cost - total.liability)}</h2>
          </>
        )}

        <Change value={change.costValue.dollar} text={$(change.costValue.dollar)} />
        <Change value={change.costValue.percent} text={`${safeFormatNumber(change.costValue.percent)} %`} />

        {total.liability > 0 && (
          <>
            <div>
              <h1>{t(type, { returnObjects: true }).liability}</h1>
              <h2>
                {$(total.liability)}
                <Percentage value={percent} text="% APR" />
              </h2>
            </div>
          </>
        )}
      </header>
    )
  }

  render() {
    const { item } = this.props

    return (
      <Content
        className="Equity"
        style={{
          ...this.props.style,
          ...Cushion.FULL_WIDTH
        }}
      >
        {this.renderHeader()}
        <main>
          <Transactions item={item} />
        </main>
      </Content>
    )
  }
}

export default withTranslation('translations')(Equity)
