import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Alignment,
  Button,
  AnchorButton,
  Intent,
  Navbar,
  Popover,
  Menu,
  MenuItem,
} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { useTranslation } from 'react-i18next'
import page from 'page'

import AppContext from '../AppContext'
import { Structure } from '../constants'

const Header = ({ style }) => {
  const { t } = useTranslation()
  const { action, user, constants, header, auth } = useContext(AppContext)
  const { ASSET_TYPES } = constants

  const onLogin = async () => {
    await auth.loginWithRedirect()
  }

  const onLogout = async () => {
    const { email } = user

    await auth.logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI
      }
    })

    action.reset('user', () => {
      action.setStructure(Structure.DEFAULT, () => {
        action.reset('history')
      })
    })

    action.addToast({
      icon: IconNames.TICK,
      intent: Intent.SUCCESS,
      message: `${email} logged out`
    })
  }

  const handleAddAsset = (event) => {
    const { textContent } = event.target

    console.log({ textContent })
  }

  return (
    <header className="Header" style={style}>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <AnchorButton minimal text={process.env.APP_NAME} href="/" />
          </Navbar.Heading>
          {user && (
            <div className="bp3-input-group bp3-large">
              <span className="bp3-icon bp3-icon-search"></span>
              <input type="text" name="search" className="bp3-input" placeholder={t('Search assets, transactions, anything')} />
              <button className="bp3-button bp3-minimal bp3-intent-primary bp3-icon-arrow-right" />
            </div>
          )}
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {user && (
            <Popover content={
              <Menu>
                {Object.entries(ASSET_TYPES).map(([key, value]) => (
                  <MenuItem key={key} text={t(value, { returnObjects: true }).title} onClick={handleAddAsset} />
                ))}
              </Menu>
            }>
              <Button large minimal icon={IconNames.PLUS} text={t('Add asset')} />
            </Popover>
          )}

          <Menu>
            {header.map(headerItem => {
              const { title, pathname, onClick, button, requiredContext } = headerItem
              const isActive = pathname === `/${page.current}`

              switch (requiredContext) {
                case 'user':
                  if (!user) {
                    return null
                  }
                  break
                default:
                  break
              }

              return (
                <MenuItem
                  key={title}
                  onClick={onClick}
                  title={t(title)}
                  active={isActive}
                  {...button}
                />
              )
            }
            )}
          </Menu>

          {!user
            ? <Button large icon={IconNames.LOG_IN} onClick={onLogin} title={t('Login')} text={t('Login')} />
            : <Button minimal large icon={IconNames.LOG_OUT} onClick={onLogout} title={t('Logout')} />
          }
        </Navbar.Group>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
}

export default Header
