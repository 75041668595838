import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup } from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'

const DateInput = ({ item, setAsset, getTranslation }) => {
  const { label, labelInfo, helperText } = getTranslation('date')

  return (
    <FormGroup
      label={label}
      labelFor="date"
      labelInfo={labelInfo}
      helperText={helperText}
    >
      <DatePicker
        onChange={date => setAsset(prevAsset => ({
          ...prevAsset,
          date
        }))}
        defaultValue={item.date}
        showActionsBar
      />
    </FormGroup>
  )
}

DateInput.propTypes = {
  item: PropTypes.object.isRequired,
  setAsset: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired
}

export default DateInput